<template>
  <div>
    <div class="c-section">
      <h1 class="c-title2">DEBUG</h1>
    </div>

    <div class="c-section c-section--mb64">
      <h2 class="c-title3">BACKEND API</h2>
      <p>
        ボタンを押してレスポンスが表示されれば、バックエンドAPIと正常に疎通しています。
      </p>
      <Button style-type="primary" fluid @click="getCustomer">
        GET CUSTOMER
      </Button>

      <h2 class="c-title4">Response</h2>
      <pre>{{ res }}</pre>
    </div>

    <div class="c-section">
      <h2 class="c-title3">NATIVE</h2>

      <p>ボタンを押すと、ネイティブ側の関数を実行し、結果を出力します。</p>
      <Button style-type="primary" fluid @click="getAccessToken">
        GET ACCESS TOKEN
      </Button>
      <h2 class="c-title4">Response</h2>
      <pre>{{ nativeRes }}</pre>
    </div>
  </div>
</template>

<script>
import Repository from '@/repositories/Repository';
import { useNativeConnection } from '@/composables/useNativeConnection';
const { getAccessToken } = useNativeConnection();

export default {
  data: () => ({
    res: null,
    nativeRes: null
  }),

  methods: {
    async getCustomer() {
      try {
        const { data } = await Repository.get('/customer');
        this.res = data;
      } catch (e) {
        this.res = 'Error!';
      }
    },

    async getAccessToken() {
      try {
        const res = await getAccessToken();
        this.nativeRes = res;
      } catch (e) {
        this.nativeRes = e;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
pre {
  font-size: 1.2rem;
}
</style>
