var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm._m(0),
    _c(
      "div",
      { staticClass: "c-section c-section--mb64" },
      [
        _c("h2", { staticClass: "c-title3" }, [_vm._v("BACKEND API")]),
        _c("p", [
          _vm._v(
            " ボタンを押してレスポンスが表示されれば、バックエンドAPIと正常に疎通しています。 "
          ),
        ]),
        _c(
          "Button",
          {
            attrs: { "style-type": "primary", fluid: "" },
            on: { click: _vm.getCustomer },
          },
          [_vm._v(" GET CUSTOMER ")]
        ),
        _c("h2", { staticClass: "c-title4" }, [_vm._v("Response")]),
        _c("pre", [_vm._v(_vm._s(_vm.res))]),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "c-section" },
      [
        _c("h2", { staticClass: "c-title3" }, [_vm._v("NATIVE")]),
        _c("p", [
          _vm._v(
            "ボタンを押すと、ネイティブ側の関数を実行し、結果を出力します。"
          ),
        ]),
        _c(
          "Button",
          {
            attrs: { "style-type": "primary", fluid: "" },
            on: { click: _vm.getAccessToken },
          },
          [_vm._v(" GET ACCESS TOKEN ")]
        ),
        _c("h2", { staticClass: "c-title4" }, [_vm._v("Response")]),
        _c("pre", [_vm._v(_vm._s(_vm.nativeRes))]),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "c-section" }, [
      _c("h1", { staticClass: "c-title2" }, [_vm._v("DEBUG")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }